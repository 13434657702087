import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import BackgroundImage from 'gatsby-background-image-es5';
import GatsbyImage from 'gatsby-image';
import ReactMarkdown from 'react-markdown';
import TestimonialCarousel from '../components/TestimonialCarousel';
import {faAward} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PanelLogos from '../components/PanelLogos';
import { Helmet } from 'react-helmet';
export default () => {
  const data = useStaticQuery(graphql`
  query AboutQuery {
    vision: graphCmsTextContent(identifier: {eq: "vision"}) {
      identifier
      content
    }
    mission: graphCmsTextContent(identifier: {eq: "mission"}) {
      identifier
      content
    }
    history: graphCmsTextContent(identifier: {eq: "history"}) {
      identifier
      content
    }
    values: graphCmsTextContent(identifier: {eq: "values"}) {
      identifier
      content
    }
    slogan: graphCmsTextContent(identifier: {eq: "slogan"}) {
      identifier
      content
    }
    banner: graphCmsAsset(fileName: {eq: "ck-yeo-5J6VUR6r9Wc-unsplash.jpg"}) {
      localFile{
        childImageSharp {
          fluid(maxWidth:1920,quality:90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    stock: graphCmsAsset(fileName: {eq: "gabrielle-henderson-HJckKnwCXxQ-unsplash.jpg"}) {
      localFile{
        childImageSharp {
          fluid(maxWidth:1920,quality:90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    insurance1: graphCmsAsset(fileName: {eq: "insurance1.png"}) {
      localFile{
        childImageSharp {
          fluid(maxWidth:1920,quality:90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    insurance2: graphCmsAsset(fileName: {eq: "insurance2.png"}) {
      localFile{
        childImageSharp {
          fluid(maxWidth:1920,quality:90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
  
  `)
  return (
    <>
    <Helmet>
      <title>About Us | Great Pine Resources</title>
      <meta name="description" content="Who we are - Great Pine Resources" />
    </Helmet>
    <BackgroundImage
      className=""
      Tag="section"
      fluid={data.banner.localFile.childImageSharp.fluid}
      backgroundColor={`#fff`}>
    <div className=' slogan-div flex flex-col md:flex-row  justify-between px-12 py-14  text-white bg-black bg-opacity-40'>
      <h1 className='font-bold font-mont text-3xl'>About Us</h1>
      <div className='text-left md:text-right'>
        <p className='italic'>"{data.slogan.content}"</p>
        <p className='font-medium'>- Great Pine Resources</p>
      </div>
    </div></BackgroundImage>
    
    
    <div className='history-div flex flex-row'>
      <div className='w-auto lg:w-3/5 p-12 bg-yellow-base'>
        <h2 className='text-2xl font-mont font-bold pb-2'>Our History</h2>
        <ReactMarkdown children={data.history.content}/>
        
      </div>
      
      <div className='w-2/5 hidden lg:block '>
        <GatsbyImage fluid={data.stock.localFile.childImageSharp.fluid} className='max-h-full min-h-full'/>
      </div>
    </div>

    <div className='misivisi-div flex flex-col lg:flex-row'>
      <div className='rounded-lg shadow-lg border border-gray-200 m-3 p-10 w-auto lg:w-1/3'>
        <h2 className='text-2xl font-mont font-bold pb-2 '>Our Mission</h2>
        <ReactMarkdown children={data.mission.content}/>
      </div>
      <div className='rounded-lg shadow-lg border border-gray-200  m-3 p-10 w-auto lg:w-1/3'>
        <h2 className='text-2xl font-mont font-bold pb-2'>Our Values</h2>
        <ReactMarkdown children={data.values.content}/>
      </div>
      <div className='rounded-lg shadow-lg border border-gray-200 m-3 p-10 w-auto lg:w-1/3'>
        <h2 className='text-2xl font-mont font-bold pb-2'>Our Vision</h2>
        <ReactMarkdown children={data.vision.content}/>
      </div>
    </div>

    <hr style={{border:'1px solid #DADADA'}} className='mx-10 my-5'/>

    <div className='testimonial-di h-full v'>
      <h2 className='text-xl text-center text-yellow-dark w-full'>Customer Testimonials</h2>
      <div>
      <TestimonialCarousel styles='hidden lg:block' slides={4} height={100}/>
      <TestimonialCarousel styles='hidden md:block lg:hidden ' slides={2} height={110}/>
      <TestimonialCarousel styles='block md:hidden' slides={1} height={130}/>
      </div>

    </div>

    <div className='awards-div p-4 lg:p-14 bg-yellow-base relative'>
      <h2 className='text-3xl p-2 font-bold font-mont'>Our Awards and Certifications</h2>
      <div className="flex flex-wrap -mx-px overflow-hidden sm:-mx-px">
        <div className="my-5 px-10 w-full overflow-hidden  sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2">
          <p className='text-white text-2xl font-bold'>AXA Affin General Insurans Bhd.</p>
          <div className='pl-3'>
          <p>2012 – 2013  <strong>AXA Bronze Club Member</strong></p>
          <p>2014 – 2015  <strong>AXA Sliver Club Member</strong></p>
          <p>2016 – 2017  <strong>AXA Gold Member</strong></p>
          <p>2018 – 2020  <strong>AXA Platinum Member</strong></p>
          </div>
        </div>

        <div className="my-5 px-10 w-full overflow-hidden  sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2">
          <p className='text-white text-2xl font-bold'>MPI Generali Insurans Bhd.</p>
          <div className='pl-3'>
          <p>RC 2016, 2017 <strong>Silver Hawk</strong></p>
          <p>RC 2018 <strong>Enterprise Eagle</strong></p>
          <p>RC 2019, 2020 <strong>Bronze Lion</strong></p>
          </div>
        </div>

        <div className="my-5 px-10 w-full overflow-hidden  sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2">
          <p className='text-white text-2xl font-bold'>Berjaya Sompo Insurans Bhd.</p>
          <div className='pl-3'>
          <p>2014, 2019 <strong>Gold Award</strong></p>
          <p>2015, 2017 <strong>Silver Award</strong></p>
          </div>
        </div>
      </div>
      <FontAwesomeIcon icon={faAward} className="absolute z-50 right-10 bottom-10 text-6xl text-white"/>
    </div>

    <div className='panel-div'>
      <h2 className='text-xl text-center text-yellow-dark w-full py-10'>Our Panel of Insurances</h2>
      <hr style={{border:'1px solid #DADADA'}} className='mx-10'/>
      <PanelLogos/>
    </div>
    </>


  )
}