import React from 'react'
import {graphql,useStaticQuery} from 'gatsby'
import GatsbyImage from 'gatsby-image'
export default () => {
    const {allGraphCmsCompany:{nodes}} = useStaticQuery(graphql`
    query LogoQuery {
        allGraphCmsCompany(sort: {fields: priority}) {
        nodes {
            logo {
                localFile{
                    childImageSharp {
                      fluid(maxWidth:1920,quality:90) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                }
        }
      }
    }
  }
`) 
return(
    <div className='flex flex-row justify-center flex-wrap'>
        {nodes.map(node =>
            <GatsbyImage 
                className='pointer-events-none'
                imgStyle={{ objectFit: 'contain',  }}
                style={{ width: '100%',maxWidth:'200px', maxHeight:'150px',margin: '1rem'}}
                fluid={node.logo.localFile.childImageSharp.fluid}/>
            )}
    </div>
)
}

