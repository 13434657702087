import React from 'react'
import {useStaticQuery, graphql} from 'gatsby'
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faChevronCircleRight, faChevronCircleLeft, faComments} from '@fortawesome/free-solid-svg-icons'

export default (props)=>{
    const {allGraphCmsTestimonial:{nodes}} = useStaticQuery(graphql`
    query TestimonialQuery {  
      allGraphCmsTestimonial {
        nodes {
          customer
          content
        }
      }
    }`)

    return (
        <CarouselProvider
        naturalSlideWidth={100}
        naturalSlideHeight={props.height}
        totalSlides={nodes.length}
        visibleSlides={props.slides}
        infinite={true}
        interval={4500}
        step={props.slides}
        dragStep={props.slides}
        className={`${props.styles} h-auto relative`}
        >
        
        <div className='relative'>
        <Slider className='p-10'>
          {nodes.map((node, i)=>
              <Slide index={i} key={i}>
                  <div className='bg-white border border-gray-200 rounded-lg shadow-xl p-5 m-2 flex flex-col justify-between h-full z-50'>
                    <p className='overflow-y-auto'>{node.content}</p>
                    <div className='text font-bold flex flex-row justify-between pt-5'  style={{borderTop:'1px solid #DADADA'}}>
                      <FontAwesomeIcon icon={faComments} className='text-yellow-base text-2xl'/>
                      <p className='text-sm overflow-hidden whitespace-nowrap'>{node.customer}</p>
                    </div>
                  </div>
              </Slide>
          )}
          
        </Slider>
        
        </div>
        <ButtonBack className='absolute text-black z-50 left-0 top-1/2'>
          <FontAwesomeIcon className='mx-3 text-3xl shadow-xl opacity-50' icon={faChevronCircleLeft}/>
        </ButtonBack>
        <ButtonNext className='absolute text-black z-50 right-0 top-1/2'>
          <FontAwesomeIcon className='mx-3 text-3xl shadow-xl opacity-50' icon={faChevronCircleRight}/>
        </ButtonNext>
        
        </CarouselProvider>
    )
}